<template>
  <b-container class="bg-white py-4">
    <b-row v-if="!permissionEdit">
      <b-col>
        <b-card>
          <h4>You do not have access permission for this page</h4>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="permissionEdit && !loading">
      <b-col>
        <b-container>
          <b-row class="mb-4">
            <b-col>
              <router-link :to="{ name: 'Comm', params: { id: row.id }}">back to the communication</router-link>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col>
                <h4>Adding new comms</h4>
                <div class="mb-2"><strong>Comms body</strong></div>
                  <b-form-textarea id="bodyInput"
                                  v-model="row.body"
                                  placeholder="Comms content"
                                  required
                                  rows=4
                                  class="mb-2">
                  </b-form-textarea>
                  <div class="mb-2"><strong>Risks</strong></div>
                  <b-form-input   id="riskInput"
                                  v-model="row.risks"
                                  placeholder="Risks"
                                  required
                                  class="mb-2">
                  </b-form-input>

                  <div class="mb-2"><strong>Detection difficulty</strong></div>
                  <b-form-select class="mb-2" v-model="row.difficulty" :options="options" />

                <div class="mb-2"><strong>Comments</strong></div>
                  <b-form-textarea id="detailsInput"
                                  v-model="row.comments"
                                  placeholder="Comments"
                                  required
                                  rows=4
                                  class="mb-2">
                  </b-form-textarea>
                <b-button v-if="validate" class="mr-2" variant="primary" size="sm" v-on:click="save">Save</b-button>
                <router-link class="btn primary-outline" :to="{ name: 'Comm', params: { id: row.id }}">Cancel</router-link>
            </b-col>
          </b-row>

          <b-row class="my-4">
            <b-col>
              <h5>Delete</h5>
              <b-button variant="danger" v-on:click="deleteRow">Confirm delete</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from 'moment'

import ac from '../libs/accesscontrol'

export default {
  name: 'AlertEdit',
  beforeDestroy () {},
  components: {
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validate: function () {
      return this.row.body && this.row.risks && this.row.comments
    }
  },
  created: function () {
    this.permissionEdit = ac.can(this.user.acgroups).createAny('comm').granted
    this.load()
  },
  data () {
    return {
      loading: true,
      options: [
        { value: 1, text: '1 - low' },
        { value: 2, text: '2 - medium' },
        { value: 3, text: '3 - high' }
      ],
      permissionEdit: false,
      saving: false,
      row: {
        body: '',
        risks: '',
        comments: ''
      }
    }
  },
  methods: {
    deleteRow: async function () {
      this.$logger.debug('deleteRow started')
      this.saving = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/alert`
        let params = {
          body: {
            id: this.row.id
          }
        }
        let response = await this.$Amplify.API.del(apiName, path, params)
        this.$logger.debug('deleted OK:', response)
        this.$router.push({ name: 'Alerts' })
      } catch (e) {
        this.$logger.warn('delete error: ' + e)
      }
      this.saving = false
    },
    load: async function () {
      this.$logger.debug('load started')
      this.loading = true
      try {
        let apiName = 'sql'
        let path = `/comm/${this.$route.params.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.row = response
        this.loading = false
      } catch (e) {
        this.$logger.warn('/alert/:id error', e)
      }
    },
    save: async function (property) {
      this.$logger.debug(`saving ${property} start`)
      this.saving = true
      try {
        // let response = await this.$http(config)
        let apiName = 'sql'
        let path = `/comm/` + this.row.id
        this.row.id = Number(this.row.id)
        this.row.updated_at = moment()
        this.row.user_updated = this.user.username
        let params = {
          body: this.row
        }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$logger.debug('saved OK:', response)
        this.$router.push({ name: 'Comm', params: { id: this.row.id } })
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.saving = false
    }
  },
  mounted () {},
  watch: {}
}
</script>

<style>
</style>
